<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" style="max-height: 65vh; overflow-y: auto">
              <ElRow :gutter="20">
                <ElCol :span="24">
                  <ElFormItem prop="first_name" :label="$t('first_name')">
                    <ElInput
                      v-model="form.first_name"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <ElFormItem prop="last_name" :label="$t('last_name')">
                    <ElInput
                      v-model="form.last_name"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <ElFormItem prop="email" :label="$t('email')">
                    <ElInput
                      v-model="form.email"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <ElFormItem prop="phone" :label="$t('phone_number')">
                    <ElInput
                      v-model="form.phone"
                      type="tel"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    >
                      <template #prepend>
                        <ElSelect
                          v-model="form.phone_country"
                          placeholder=""
                          filterable
                          :fallback-placements="['top', 'bottom']"
                          :popper-options="getSelectPopperOptions()"
                          popper-class="custom-selector"
                        >
                          <template #prefix>
                            <img
                              v-if="selectedPhoneCountry"
                              :src="selectedPhoneCountry.flag"
                            />
                          </template>
                          <ElOptionGroup
                            v-for="(group, index) in countries"
                            :key="index"
                          >
                            <ElOption
                              :class="
                                form.phone_country === item.phone_country
                                  ? 'selected'
                                  : ''
                              "
                              v-for="item in group.options"
                              label=""
                              :value="item.phone_country"
                              :key="item.iso_code"
                            >
                              <template #default>
                                <img :src="item.flag" :alt="item.iso_code" />
                                <span
                                  >{{ item.iso_code }} ({{
                                    item.phone_country
                                  }})</span
                                >
                              </template>
                            </ElOption>
                          </ElOptionGroup>
                        </ElSelect>
                      </template>
                    </ElInput>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <ElFormItem prop="roles" :label="$t('roles')">
                    <ElSelect
                      v-model="form.roles"
                      :autocomplete="true"
                      style="width: 100%"
                      multiple
                      :disabled="!hasRole('admin')"
                      :placeholder="$t('select_user_roles')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in roles"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
              </ElRow>
            </ElCol>
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_USER from '@/graphql/user/mutation/createUserNoAddress.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  first_name: '',
  last_name: '',
  enabled: true,
  accept_notifications: true,
  roles: [],
  email: '',
  phone_country: '',
  phone: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const countries = getModelCountries()
const documentTypes = getDocumentTypes()
const roles = getAllRoles()

const phoneNumberElForm = (rule, value, callback) => {
  if (!phoneNumber(value, form)) {
    return callback(t('phone_length_invalid'))
  }
  return callback()
}

const rules = {
  email: { required },
  phone: { phoneNumber }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  email: [
    { required: true, message: t('email_is_required'), trigger: ['blur', 'change'] },
    { type: 'email', message: t('invalid_email'), trigger: ['blur', 'change'] }
  ],
  phone: [
    {
      validator: phoneNumberElForm,
      trigger: ['blur', 'change']
    }
  ]
})

const selectedPhoneCountry = computed(() => {
  for (const elem of countries) {
    const country = elem.options.find(
      (item) => item.phone_country === form.phone_country
    )
    if (country) {
      return country
    }
  }
  return null
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    first_name: form.first_name,
    last_name: form.last_name,
    email: form.email,
    phone_country: form.phone_country,
    phone: form.phone,
    enabled: form.enabled,
    accept_notifications: form.accept_notifications,
    roles: []
  }

  if (hasRole('admin')) {
    variables.roles = form.roles?.map((role) => {
      return role instanceof Object ? role.name : role
    }) ?? []
  } else {
    delete variables.roles
  }
  const response = await mutation(CREATE_USER, variables)
  if (response.error) {
    loading.value = false
    if (response.error.message.includes('input.email')) {
      $showError(t('email_already_registered'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createUser ?? null)
    loading.value = false
  }
}
</script>

<style>
</style>